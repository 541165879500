
import { computed, defineComponent, nextTick, onMounted } from "vue";
import { ref } from "vue";

//service
import ProductService from "@/service/product-service/ProductService";
import { useRoute, useRouter } from "vue-router";

//interface
import {
  Product,
  ProductFilter,
  ProductDetail,
} from "@/types/product/Product.interface";

//component
import ProductList from "@/components/product/ProductList.vue";

//store
import { useStore } from "@/store";

export default defineComponent({
  components: {
    ProductList,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const products = ref<Product[]>([]);
    const productCount = ref<number>(0);
    const productService = ref(new ProductService());
    const store = useStore();
    const filter = ref<ProductFilter>({
      brandId: 0,
      search: "",
      perPage: 10,
      pageNo: 1,
      mainCategoryUrlKey: "",
      categoryUrlKey: "",
    });

    onMounted(async () => {
      filter.value.brandId = computed(
        () => store.getters.getBrand.brandId
      ).value;
      if (route.params.category) {
        filter.value.mainCategoryUrlKey = route.params.category.toString();
      }
      if (route.query.search) {
        filter.value.search = route.query.search.toString();
      }

      nextTick(async () => {
        await getProducts(filter.value, false);
      });
    });

    const getProducts = async (
      filter: ProductFilter,
      scrollFlag: boolean,
      $state?: any
    ) => {
      if (!scrollFlag) {
        filter.pageNo = 1;
        products.value = [];
      }

      await productService.value
        .getProducts(filter)
        .then((data: ProductDetail) => {
          productCount.value = data.total;
          if (scrollFlag) {
            if (data.productList.length == 0) {
              if ($state) $state.complete();
            } else {
              products.value = [...products.value, ...data.productList];
              if ($state) $state.loaded();
            }
          } else {
            products.value = data.productList;
            if ($state) $state.loaded();
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    const infiniteHandler = async ($state: any) => {
      setTimeout(async () => {
        filter.value.pageNo++;
        await getProducts(filter.value, true, $state);
      }, 500);
    };

    const handleSearch = (e: any) => {
      const redirectUri = `/${route.params.initialId}/search?search=${filter.value.search}&sessionId=${route.query.sessionId}`;
      if (e.keyCode === 13) {
        filter.value.pageNo = 1;
        router.push(redirectUri);
      }
    };

    return {
      products,
      productCount,
      filter,
      infiniteHandler,
      backgroundColor: computed(() => store.getters.getBrand.colorThemeMain),
      handleSearch,
    };
  },
});
