
import ResponseData from "@/types/response/Response.interface"
import axios from "../instance/AxiosInstance"
import { ProductFilter, ProductDetail, ProductResponse } from "@/types/product/Product.interface"

export default class ProductService {


    async getProducts(filter: ProductFilter): Promise<ProductDetail> {
        return await axios.post('/category', filter)
            .then((res: ResponseData<ProductResponse>) => {
                if (res.data.result == 1) {
                    return res.data.detail as ProductDetail
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }



}
