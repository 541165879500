
import { defineComponent, PropType, computed } from "vue";
import { Product } from "@/types/product/Product.interface";
import InfiniteLoading from "@/components/infinite-loading/InfiniteLoading.vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { CategoryBreadcrumb } from "@/types/category/Category.interface";

export default defineComponent({
  props: {
    products: {
      required: true,
      type: Array as PropType<Product[]>,
    },
    productCount: {
      required: true,
      type: Number,
    },
  },
  components: {
    InfiniteLoading,
  },
  emits: ["infiniteHandler"],
  setup(props, { emit }) {
    const store = useStore();

    const router = useRouter();
    const route = useRoute();

    const infiniteHandler = ($state: any) => {
      emit("infiniteHandler", $state);
    };

    const onRouteToProductDetail = (productId: number) => {
      const redirectUri = `/${route.params.initialId}/product/detail/${productId}?sessionId=${route.query.sessionId}`;
      router.push(redirectUri);
    };

    const handleBreadcrumb = (item: CategoryBreadcrumb) => {
      let redirectUri = "";
      if (!item.urlKey || item.urlKey == "All") {
        redirectUri = `/${route.params.initialId}/product?sessionId=${route.query.sessionId}`;
      } else {
        redirectUri = `/${route.params.initialId}/product/${item.urlKey}?sessionId=${route.query.sessionId}`;
      }
      router.push(redirectUri);
    };

    return {
      brand: computed(() => store.getters.getBrand),
      infiniteHandler,
      onRouteToProductDetail,
      handleBreadcrumb,
      breadcrumb: computed(() => store.getters.getCategoryBreadCrumb),
    };
  },
});
