
import { defineComponent } from "vue";
import InfiniteLoading from "infinite-loading-vue3-ts";

export default defineComponent({
  components: {
    InfiniteLoading,
  },
  emits: ["infiniteHandler"],
  setup(props, { emit }) {
    const infiniteHandler = ($state: any) => {
      emit("infiniteHandler", $state);
    };
    return { infiniteHandler };
  },
});
